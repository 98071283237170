import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button, Space } from 'antd';
import { API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY, API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_EXPORT, get } from '../../App/Api';
import { PerpageRoutes } from './PerPageRoutes';
import { AccordeonFiltersRoutes } from './AccordeonFilterRoutes';
import { useTranslation } from 'react-i18next';
import { saveToLocalStorage } from '../Login/storage';
import { EmployeeReportingProductivity } from './Details';
import MainLayout from '../../Layout/MainLayout';

export function EmployeeReporting() {
	const { t, i18n } = useTranslation();
	const [routes, setRoutes] = useState([]);
	const [routesPerPage, setRoutesPerPage] = useState(10);
	const [routesPage, setRoutesPage] = useState(1);
	const [employeeIds, setEmployeeIds] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [fleetIds, setFleetIds] = useState([]);
	const [fleetCities, setFleetCities] = useState([]);
	const [fleetStates, setFleetStates] = useState([]);
	const [searchReference, setSearchReference] = useState(null);
	const [clientIds, setClientIds] = useState([]);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);

	const openRoutesDetails = (employee) => {
		setSelectedEmployee(employee);
	};

	const closeRoutesDetails = () => {
		setSelectedEmployee(null);
	};

	useEffect(() => {
		fetchData();
	}, [routesPerPage, routesPage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);

		try {
			let url = API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY;

			const response = await get(url, {
				perPage: routesPerPage,
				page: routesPage,
				startDate,
				endDate,
				fleetIds,
				fleetCities,
				fleetStates,
				references: searchReference,
				employeeIds,
				clientIds,
			});

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === routesPerPage);
				setRoutes(response.data);
				setError(null);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error?.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const loadMoreRoutes = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setRoutesPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (routesPage > 1) {
			setRoutesPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setRoutesPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};
	const handleInputOrderSearch = (value) => {
		if (!value) {
			value = null;
		}
		setSearchReference(value);
		saveToLocalStorage('routes.searchReference', value);
	};

	const columns = [
		{
			title: t('fleets.name'),
			dataIndex: 'fleetName',
			key: 'fleetName',
			align: 'center',
		},
		{
			title: t('employees.name'),
			dataIndex: 'employeeName',
			key: 'employeeName',
			align: 'center',
		},
		{
			title: t('orders.qty'),
			dataIndex: 'orders',
			key: 'orders',
			align: 'center',
		},
		{
			title: t('actions'),
			key: 'actions',
			render: (employee) => (
				<Space>
					<Button onClick={() => openRoutesDetails(employee)}>
						{t('routes.detail')}
					</Button>
				</Space>
			),
		},
	];

	const handleExport = async () => {
		setLoading(true);
		try {
			const response = await get(
				API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_EXPORT,
				{
					startDate,
					endDate,
					fleetIds,
					fleetCities,
					fleetStates,
					references: searchReference,
					employeeIds,
					clientIds,
				},
				{
					responseType: 'blob',
				}
			);

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Employee_Reporting_Productivity_${Date.now()}.xlsx`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			setError(error?.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const filterProps = {
		setStartDate,
		setEndDate,
		setFleetIds,
		setFleetCities,
		setFleetStates,
		setEmployeeIds,
		setClientIds,
		handleSearch,
		handleInputOrderSearch,
	};

	return (
		<MainLayout>
			<div
				className="filters"
				style={{ display: !selectedEmployee ? 'block' : 'none' }}
			>
				<form onKeyDown={handleKeyPress}>
					<AccordeonFiltersRoutes filterProps={filterProps} />
				</form>
			</div>
			{!selectedEmployee && (
				<>
					{loading ? (
						<Spin size="large" />
					) : error ? (
						<Alert message="Error" description={error} type="error" showIcon />
					) : (
						<>
							<div className="employee-footer">
								<Button type="primary" onClick={handleExport}>
									{t('export')}
								</Button>
								<Button
									onClick={handlePreviousPage}
									disabled={routesPage === 1}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreRoutes}
									disabled={!hasMorePages || isLoadingMore}
								>
									{t('nextPage')}
								</Button>
								<PerpageRoutes
									routesPerPage={routesPerPage}
									setRoutesPerPage={setRoutesPerPage}
								/>
							</div>
							<div className="routes-table">
								<Table
									dataSource={routes}
									columns={columns}
									rowKey="fleetId"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}

			{selectedEmployee && (
				<EmployeeReportingProductivity
					closeRoutesDetails={closeRoutesDetails}
					selectedEmployee={selectedEmployee}
					startDate={startDate}
					endDate={endDate}
				/>
			)}
		</MainLayout>
	);
}
