import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

export const DateFilter = ({ setStartDate, setEndDate, date }) => {
	const dateFormat = 'YYYY-MM-DD';
	const currentDate = moment().format('YYYY-MM-DD');

	function handleChange(dates) {
		let newStartDate = null;
		let newEndDate = null;
		if (dates?.[0]) {
			newStartDate = moment(dates?.[0]?.$d).format('YYYY-MM-DD');
		}

		if (dates?.[1]) {
			newEndDate = moment(dates?.[1]?.$d).format('YYYY-MM-DD');
		}

		setStartDate(newStartDate);
		setEndDate(newEndDate);
	}

	return (
		<DatePicker.RangePicker
			onChange={handleChange}
			style={{ width: '100%', margin: '10px 0' }}
			defaultValue={[dayjs(currentDate, dateFormat), dayjs(currentDate, dateFormat)]}
			maxDate={dayjs(currentDate, dateFormat)}

		/>
	);
};
