import React from 'react';
import { InputOrderSearch } from '../SearchReference';
import { OrderStatusCodeFilter } from '../OrderStatusCodesFilter';
import { Button, Col, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClientFilter } from '../../../Client/Filter';
import { EmployeeFilter } from '../../../Employee/Filter';
import { BusinessFilter } from '../../../Business/Filter';
import { FleetFilter } from '../../../Fleet/Filter';
import { OperatorFilter } from '../../../Operator/Filter';

export function AccordionFilters({ filterProps }) {
	const { t } = useTranslation();
	const items = [
		{
			key: 'mainAccordion',
			label: t('orders.accordion.Toggle-Filters'),
			children: (
				<>
					<Row gutter={[16, 16]}>
						<Col span={8}>
							<p style={{ fontSize: '11px', margin: '5px 0' }}>
								{t('orders.reference')}
							</p>

							<InputOrderSearch {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '11px', margin: '5px 0' }}>
								{t('orders.accordion.Bussines')}
							</p>

							<BusinessFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '13px', margin: '5px 0' }}>
								{t('clients.name')}
							</p>
							<ClientFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '11px', margin: '5px 0' }}>
								{t('operators.name')}
							</p>
							<OperatorFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '11px', margin: '5px 0' }}>
								{t('fleets.name')}
							</p>
							<FleetFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '11px', margin: '5px 0' }}>
								{t('employees.name')}
							</p>
							<EmployeeFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '11px', margin: '5px 0' }}>
								{t('orders.accordion.Orders-Status-Codes')}
							</p>
							<OrderStatusCodeFilter {...filterProps} />
						</Col>
					</Row>
					<Row justify="end">
						<Col>
							<Button type="primary" onClick={filterProps.handleSearch}>
								{t('orders.accordion.Search')}
							</Button>
						</Col>
					</Row>
				</>
			),
		},
	];

	return (
		<Collapse
			accordion
			items={items}
			style={{
				backgroundColor: '#fff',
				borderRadius: '8px',
				marginBottom: '1rem',
				width: '100%',
				margin: '0 auto',
			}}
		/>
	);
}
