import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { API_URL_OPERATORS_SELECT, get } from '../../App/Api';
import { useTranslation } from 'react-i18next';

export function OperatorFilter({ mode = 'multiple', setOperatorIds, operatorIds = [] }) {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState({});

	useEffect(() => {
		fetchOperators();
	}, [i18n.language]);

	const fetchOperators = async () => {
		try {
			const response = await get(API_URL_OPERATORS_SELECT);

			let newOptions = [];

			if (response?.data?.length) {
				newOptions = response.data.map((operator) => {
					return {
						value: operator.operatorId,
						label: operator.name,
					};
				});
			}
			setOptions(newOptions);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleOptionsChange = (value) => {
		setOperatorIds(value);
	};

	return (
		<Select
			showSearch
			mode={mode}
			optionFilterProp="label"
			onChange={handleOptionsChange}
			defaultValue={operatorIds}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.operators')}
			options={options}
		></Select>
	);
}
